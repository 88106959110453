<template>
  <div class="flex flex-col flex-wrap text-left border border-gray">
    <component
      :is="isSimple ? 'div' : 'button'"
      :class="[!isSimple ? 'group aspect-w-16 aspect-h-9 relative block w-full bg-gray overflow-hidden' : null]"
      @click.prevent="isSimple ? null : showVideo()"
    >
      <img
        :class="[
          'lg:group-hover:scale-105 absolute h-full w-full object-cover z-10 transform-gpu transition-all ease-in-out duration-300',
          !isImageLoaded ? 'opacity-0' : 'opacity-100'
        ]"
        :src="imagePath"
        :alt="video.name"
        @load="imageLoaded"
      />
      <BaseIcon
        v-if="isImageLoaded"
        class="
          absolute
          z-10
          left-1/2
          top-1/2
          w-12
          h-12
          text-white
          transform-gpu
          -translate-x-1/2 -translate-y-1/2
          transition-all
          duration-150
          ease-in-out
          lg:w-16
          lg:h-16
        "
      >
        <PlayIcon />
      </BaseIcon>
      <span
        :class="[
          'block bg-orange-500 w-full h-full absolute top-0 left-0 bottom-0 right-0 z-10 transform-gpu -skew-x-12 transition-all swipe duration-300',
          !isImageLoaded ? '-translate-x-120' : 'translate-x-120'
        ]"
      ></span>
      <div
        :class="[
          'text-white flex items-center justify-center transform-gpu transition-all ease-in-out duration-300',
          isImageLoaded ? 'opacity-0' : 'opacity-100'
        ]"
      >
        <AtomLogo />
      </div>
    </component>
    <div v-if="!isSimple" class="flex flex-1 flex-col justify-between p-4 pt-2 w-full lg:p-6 lg:pt-4">
      <div class="flex flex-1 flex-col justify-between w-full">
        <div>
          <div v-if="circuitUrl || isSimple" class="text-2xl font-bold leading-9">
            <p>
              <template v-if="video.name">{{ video.name }}</template>
              <template v-else-if="video.video_name">{{ video.video_name }}</template>
              <template v-else>
                {{ video.circuit_name }}
                <template v-if="video.layout_name && video.layout_name !== 'Full Circuit'">
                  - {{ video.layout_name }}
                </template>
              </template>
            </p>
          </div>
          <div v-if="!isSimple" class="grid gap-2 grid-cols-2 mt-4">
            <RouterLink
              v-if="circuitUrl"
              :to="circuitUrl"
              class="flex items-center hover:text-orange-500 transition-colors duration-150 ease-in-out"
              title="View circuit details"
              active-class="text-black"
              exact-active-class="text-black"
            >
              <BaseIcon class="flex-none mr-1 w-7 h-7">
                <CircuitIcon />
              </BaseIcon>
              {{ video.circuit_name }}
            </RouterLink>

            <RouterLink
              v-if="eventUrl && !isEvent"
              :to="eventUrl"
              class="flex items-center hover:text-orange-500 transition-colors duration-150 ease-in-out"
              title="View event details"
            >
              <BaseIcon class="flex-none mr-1 w-7 h-7">
                <EventIcon />
              </BaseIcon>
              <AtomFormattedDate :date="video.event_date" format="medium" />
            </RouterLink>

            <RouterLink
              v-if="profileUrl"
              :to="profileUrl"
              class="flex items-center hover:text-orange-500 overflow-hidden transition-colors duration-150 ease-in-out"
              title="View profile"
            >
              <BaseIcon class="flex-none mr-1 w-7 h-7">
                <PersonIcon />
              </BaseIcon>
              {{ videoOwner }}
            </RouterLink>

            <p v-if="video.lap_time" class="flex items-center">
              <BaseIcon class="flex-none mr-1 w-7 h-7">
                <ClockIcon />
              </BaseIcon>
              <AtomTime :duration="video.lap_time" />
            </p>

            <button
              v-if="hasComments"
              class="flex items-center hover:text-orange-500 transition-colors duration-150 ease-in-out"
              @click.prevent="showVideo()"
            >
              <BaseIcon class="flex-none mr-1 w-7 h-7">
                <CommentIcon />
              </BaseIcon>
              {{ video.comments }} comment<template v-if="video.comments > 1">s</template>
            </button>
          </div>
        </div>
        <div :class="['grid gap-4', isVideoOwner ? 'grid-cols-2' : null]">
          <button
            v-if="!isVideoOwner"
            class="
              lg:hover:border-black
              flex
              items-center
              justify-center
              mt-4
              p-2
              w-full
              text-black
              bg-white
              border border-gray
              rounded-sm
              focus:outline-none
              transition-all
              duration-300
              ease-in-out
            "
            @click.prevent="showVideo()"
          >
            Watch video
          </button>
          <button
            v-if="isVideoOwner"
            class="
              lg:hover:border-black
              flex
              items-center
              justify-center
              mt-4
              p-2
              w-full
              text-black
              bg-white
              border border-gray
              rounded-sm
              focus:outline-none
              transition-all
              duration-300
              ease-in-out
            "
            @click.prevent="buildForm()"
          >
            Edit video
          </button>
          <button
            v-if="isVideoOwner"
            class="
              lg:hover:border-black
              flex
              items-center
              justify-center
              mt-4
              p-2
              w-full
              text-black
              bg-white
              border border-gray
              rounded-sm
              focus:outline-none
              transition-all
              duration-300
              ease-in-out
            "
            @click.prevent="deleteVideo()"
          >
            Delete video
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      video: Object,
      url: String,
      isEvent: Boolean,
      isSimple: {
        type: Boolean,
        default: false
      }
    },
    emits: ['deleted'],
    data() {
      return {
        isImageLoaded: false,
        actionTitle: 'Update video',
        form: {
          cta: 'Update video',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'name',
              label: 'Name',
              elem: 'input',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            },
            {
              id: 'description',
              label: 'Description',
              elem: 'textarea',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            },
            {
              id: 'event_uid',
              label: 'Event',
              elem: 'select',
              isRequired: null,
              classes: 'sm:col-span-2',
              children: [
                {
                  tag: 'option',
                  text: 'None',
                  value: 'none'
                }
              ]
            },
            {
              id: 'circuit_uid',
              label: 'Circuit',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'layout_uid',
              label: 'Layout',
              elem: 'select',
              isRequired: 'true',
              children: [],
              disabled: true
            },
            {
              id: 'date',
              label: 'Date',
              elem: 'input',
              type: 'date',
              min: '2000-01-01',
              max: `${new Date().getFullYear()}-12-31`,
              isRequired: 'true'
            },
            {
              id: 'youtube_id',
              label: 'YouTube Video URL',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              disabled: true
            },
            {
              id: 'car_uid',
              label: 'Car',
              elem: 'select',
              isRequired: 'true',
              children: [],
              classes: 'sm:col-span-2',
              children: [
                {
                  tag: 'option',
                  text: 'None',
                  value: 'none'
                }
              ]
            }
          ]
        }
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser',
        circuits: 'circuits/getCircuits',
        today: 'getToday'
      }),
      videoUrl() {
        return `https://www.youtube.com/embed/${this.video.youtube_id}`
      },
      imagePath() {
        return `http://img.youtube.com/vi/${this.video.youtube_id}/hqdefault.jpg`
      },
      circuitUrl() {
        return this.video.circuit_slug ? `/circuit/${this.video.circuit_slug}` : null
      },
      eventUrl() {
        return this.video.event_slug ? `/event/${this.video.event_slug}` : null
      },
      profileUrl() {
        return this.video.username ? `/${this.video.username}` : null
      },
      isVideoOwner() {
        return this.loggedInUser && this.video.username === this.loggedInUser.username
      },
      hasComments() {
        return this.video.comments && this.video.comments > 0
      },
      videoOwner() {
        const uuidRegEx = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

        return !uuidRegEx.test(this.video.username)
          ? this.video.username
          : this.video.first_name && this.video.last_name
          ? `${this.video.first_name} ${this.video.last_name}`
          : this.video.username
      },
      description() {
        return this.video && this.video.description
          ? this.video.description.replace(/(?:\r\n|\r|\n)/g, '<br>').replace(/\*([^\s][^\*]+?[^\s])\*/g, '<b>$1<\/b>')
          : null
      }
    },
    methods: {
      async showVideo() {
        this.$store.dispatch('setOverlay', {
          youtube_id: this.video.youtube_id,
          uid: this.video.uid,
          video_uid: this.video.uid,
          url: this.video.uid,
          owner_uid: this.video.owner_uid,
          title: this.video.name ? this.video.name : `${this.video.circuit_name} - ${this.video.layout_name}`,
          liked: this.video.liked ? this.video.liked : null,
          content: this.video.description,
          meta: {
            username: this.video.username,
            first_name: this.video.first_name,
            last_name: this.video.last_name,
            date: this.video.event_date,
            url: this.video.event_slug,
            lap_time: this.video.lap_time,
            weather: this.video.weather,
            car: {
              name: this.video.car_name,
              description: this.video.car_description
            }
          }
        })
      },
      async deleteVideo() {
        try {
          await this.apiRequest(`/video/${this.video.uid}`, null, null, { Authorization: this.userJWT }, 'DELETE')

          this.$store.dispatch('setDialog', {
            title: 'Video Deleted!',
            content: '<p>The video has been deleted.</p>',
            ctaTitle: 'Close',
            confirmed: true
          })

          this.$emit('deleted', this.video.uid)
        } catch (e) {
          if (e) console.log(`Error deleting video: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't delete this video. Please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
        }
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        await Promise.all([
          this.$store.dispatch('circuits/getCircuits'),
          this.getUserEvents(),
          this.getUserCars(),
          this.getCircuitLayouts()
        ])

        await this.setCircuits()
        await this.setEvents()
        await this.setCars()
        this.setMaxDate()

        const date = this.video.event_date
          ? this.video.event_date.split('-')
          : this.video.video_date
          ? this.video.video_date.split('-')
          : null
        const formattedDate = date ? `${date[0]}-${date[1]}-${date[2].slice(0, 2)}` : null

        this.form.initial = {
          name: this.video.name,
          description: this.video.description,
          event_uid: this.video.event_uid,
          circuit_uid: this.video.circuitUrl,
          layout_uid: this.video.layout_uid,
          date: formattedDate,
          youtube_id: `https://www.youtube.com/watch?v=${this.video.youtube_id}`,
          car: this.video.car_uid
        }

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          method: 'PUT',
          endpoint: `/video/${this.video.uid}`,
          errorMessage: "Sorry we couldn't update your video.",
          successMessage: 'Your video has been updated.'
        })
      },
      async getUserEvents() {
        try {
          this.events = await this.apiRequest(
            `/user/${this.loggedInUser.id}/events`,
            null,
            { basic: true, start: '2000-01-01', end: this.today },
            {
              Authorization: this.userJWT
            }
          )
        } catch (e) {
          if (e) console.log('Error getEvents')
        }
      },
      async getUserCars() {
        try {
          this.cars = await this.apiRequest(`/user/${this.loggedInUser.id}/cars`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting cars: ${e.status} ${e.data}`)
        }
      },
      async getCircuitLayouts() {
        if (this.video.circuit_uid)
          try {
            const circuitLayouts = await this.apiRequest(`/circuit/${this.video.circuit_uid}/layouts`, null, null, {
              Authorization: this.userJWT
            })

            const layoutFormField = this.form.fields.find((field) => field.id === 'layout_uid')

            circuitLayouts.forEach((layout) => {
              layoutFormField.children.push({
                tag: 'option',
                text: layout.name,
                value: layout.layout_uid
              })
            })

            layoutFormField.selected = this.video.layout_uid !== null ? this.video.layout_uid : null
            layoutFormField.disabled = this.video.layout_uid !== null ? null : true
          } catch (e) {
            if (e) console.log(`Error getting layouts: ${e.status} ${e.data}`)
          }
      },
      async setEvents() {
        const eventFormField = this.form.fields.find((field) => field.id === 'event_uid')
        const pastEvents = this.events.filter((event) => {
          return Date.parse(event.start_date) <= Date.now() ? event : null
        })
        const re = /(\d{4})[-. \/](\d\d)[-. \/](\d\d)/

        pastEvents.forEach((event) => {
          const date = event.start_date.slice(0, 10).replace(re, '$3/$2/$1')
          let text = `${date} - ${event.circuit} `

          if (event.slug.includes('evening')) text += `- Evening `
          text += `(${event.organiser})`

          eventFormField.children.push({
            tag: 'option',
            text,
            value: event.uid
          })
        })

        eventFormField.selected = this.video.event_uid ? this.video.event_uid : 'none'
      },
      async setCars() {
        const carFormField = this.form.fields.find((field) => field.id === 'car_uid')

        this.cars.forEach((car) => {
          carFormField.children.push({
            tag: 'option',
            text: `${car.car_name} (${car.manufacturer} - ${car.model})`,
            value: car.uid
          })
        })

        carFormField.selected = this.video.car_uid ? this.video.car_uid : 'none'
      },
      async setCircuits() {
        const circuitFormField = this.form.fields.find((field) => field.id === 'circuit_uid')

        this.circuits.forEach((circuit) => {
          circuitFormField.children.push({
            tag: 'option',
            text: circuit.name,
            value: circuit.uid
          })
        })

        circuitFormField.selected = this.video.circuit_uid
      },
      setMaxDate() {
        const date = this.form.fields.find((field) => field.id === 'date')

        date.max = this.today
      },
      imageLoaded() {
        this.isImageLoaded = true
      }
    }
  }
</script>
