<template>{{ convertedTime }}</template>

<script>
  export default {
    props: {
      duration: {
        type: Number
      }
    },
    computed: {
      convertedTime() {
        const duration = this.duration
        let string
        let milliseconds = parseInt(duration % 1000)
        let seconds = parseInt((duration / 1000) % 60)
        let minutes = parseInt((duration / (1000 * 60)) % 60)

        seconds = seconds < 10 ? '0' + seconds : seconds
        string = `${seconds}${milliseconds ? `.${milliseconds.toString().padStart(3, '0')}` : ''}`
        if (!!minutes) string = `${minutes}:${string}`

        return string
      }
    }
  }
</script>
