<template>
  <div class="relative">
    <img
      v-if="thumbnail"
      class="flex items-center justify-center w-12 h-12 rounded-full ring-white ring-8"
      :src="coverPhoto"
      :alt="username"
    />
    <div v-else class="flex items-center justify-center p-2 w-12 h-12 text-white bg-orange-500 rounded-full">
      <AtomLogo />
    </div>
    <div
      class="
        absolute
        bottom-0
        right-0
        flex
        items-center
        justify-center
        w-6
        h-6
        text-white
        bg-orange-500
        rounded-full
        transform
        translate-x-1 translate-y-1
      "
    >
      <BaseIcon class="w-5 h-5">
        <component :is="icon" v-if="icon" />
        <EventIcon v-else />
      </BaseIcon>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      userUsername: {
        type: String
      },
      userUid: {
        type: String
      },
      icon: {
        type: String
      },
      thumbnail: {
        type: String
      }
    },
    computed: {
      coverPhoto() {
        return this.thumbnail
          ? `${process.env.VUE_APP_IMGIX}/users/${this.userUid}/${this.thumbnail}?w=64&h=64&fit=crop`
          : null
      },
      username() {
        return this.userUsername ? this.userUsername : 'Tracklimit'
      }
    }
  }
</script>

<style></style>
