<template>
  <td v-if="item.series" class="whitespace-no-wrap text-gray-900 px-6 py-4 text-sm font-medium leading-5">
    {{ item.series }}
  </td>
  <td class="whitespace-no-wrap text-gray-900 px-6 py-4 text-sm font-medium leading-5">
    <template v-if="item.circuit">
      {{ item.circuit }}
      <template v-if="item.layout !== 'None' && item.layout !== 'Full Circuit'">{{ item.layout }}</template>
    </template>
    <template v-else>––––</template>
  </td>
  <td class="whitespace-no-wrap text-gray-500 px-6 py-4 text-sm leading-5">
    <AtomFormattedDate :date="item.start_date"></AtomFormattedDate>
  </td>
  <td v-if="item.organiser" class="whitespace-no-wrap text-gray-500 px-6 py-4 text-sm leading-5">
    {{ item.organiser }}
  </td>
  <td v-if="item.format" class="whitespace-no-wrap text-gray-500 px-6 py-4 text-sm leading-5">
    {{ item.format }}
  </td>
  <td class="whitespace-no-wrap px-6 py-4 text-right text-sm font-medium leading-5">
    <RouterLink :to="rowLink"></RouterLink>
    <template v-if="loggedInUser && loggedInUser.admin">
      <button class="text-red-600" title="Delete event" @click.prevent="deleteEvent()">
        <BaseIcon>
          <CloseIcon />
        </BaseIcon>
      </button>
    </template>
  </td>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      item: Object
    },
    emits: ['delete-event'],
    computed: {
      ...mapGetters({
        loggedInUser: 'auth/getLoggedInUser',
        isAuthenticated: 'auth/getIsAuthenticated'
      }),
      rowLink() {
        return `/${this.item.series ? 'race' : 'event'}/${this.item.slug}`
      }
    },
    methods: {
      deleteEvent(eventUid) {
        this.$emit('delete-event', this.item.uid)
      }
    }
  }
</script>
