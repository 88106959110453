<template>
  <table class="min-w-full">
    <thead v-if="headings" class="hidden">
      <tr>
        <th
          v-for="heading in headings"
          :key="heading"
          class="px-6 py-3 text-left text-black text-xs font-medium tracking-wider leading-4 uppercase"
        >
          {{ heading }}
        </th>
        <th class="px-6 py-3"></th>
      </tr>
    </thead>
    <tbody v-if="items">
      <tr
        v-for="item in items"
        :key="item.id"
        class="lg:hover:bg-gray bg-transparent cursor-pointer transition-all duration-150"
        @click.prevent="triggerClick"
      >
        <AtomTableRow :item="item" @delete-event="deleteEvent" />
      </tr>
    </tbody>
  </table>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'
  import AtomTableRow from '@/components/atoms/TableRow'

  export default {
    components: {
      AtomTableRow
    },
    props: {
      items: Array,
      headings: Array
    },
    emits: ['event-deleted'],
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser',
        isAuthenticated: 'auth/getIsAuthenticated'
      })
    },
    methods: {
      triggerClick() {
        const selected = event.target

        if (selected.tagName.toLowerCase() === 'td') {
          const parent = event.target.parentNode
          const link = parent.querySelector('a')

          link.click()
        }
      },
      async deleteEvent(eventUid) {
        try {
          const res = await this.apiRequest(`/event/${eventUid}`, null, null, { Authorization: this.userJWT }, 'DELETE')

          if (!res) throw new Error()

          this.$emit('event-deleted')

          this.$store.dispatch('setDialog', {
            title: 'Event Deleted!',
            content: '<p>The event has been deleted.</p>',
            ctaTitle: 'Close',
            confirmed: true
          })

          this.$emit('event-deleted')
        } catch (e) {
          if (e) console.log(`Error deleting event: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't delete this event. Please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
        }
      }
    }
  }
</script>
